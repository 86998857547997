import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/telekom/1.png"
import img2 from "../../images/telekom/2.png"
import img3 from "../../images/telekom/3.png"
import img4 from "../../images/telekom/4.png"

import img5 from "../../images/telekom/5.png"
import img6 from "../../images/telekom/6.png"
import img7 from "../../images/telekom/7.png"

import img8 from "../../images/telekom/8.png"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Deutsche Telekom is Europe's largest telecommunications company and one of the world's biggest public companies. And they decided to enter the fast growing market of electric cars.
                    <p/>
                    For their new brand, I created the Name "GET CHARGE". Also texts for the website and content for the blog to generate traffic.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
              Die Deutsche Telekom ist Europas grösstes Telekomunternehmen und eines der weltgrössten börsennotierten Unternehmen. Und sie entschieden sich, im schnell wachsenden Elektroauto-Markt mitzumischen.
                    <p/>
                    Für ihre neue Marke Marke erarbeitete ich das Naming "GET CHARGE". Dazu die Texte für die Webseite und Content für den Blog, um Traffic zu generieren.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>




    <img src={img1}/>
    <img src={img2}/>
    <img src={img3}/>
    <img src={img4}/>
    <img src={img5}/>
    <img src={img6}/>
    <img src={img7}/>
    <img src={img8}/>








      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
